import { Injectable } from '@angular/core';
import { FirestoreService, DocItem } from './firestore.service';
import { LocalStorageService } from './local-storage.service';
import { Observable, combineLatest } from 'rxjs';
import { flatten, uniqBy } from 'lodash';
import { SearchService } from './search.service';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { Institution, InstitutionTypes, InstitutionContactPerson } from '@models/model';
import { TimestampDate } from 'timestamp-date';
import { InstitutionFilter } from 'app/institutions-module/components';
import { QueryConstraintOptions, Where } from '@shared/model/firestore';

@Injectable({
    providedIn: 'root',
})
export class InstitutionService {
    private orgId: string;
    private ref: string;
    private timestampDate = new TimestampDate();

    constructor(
        private localStorageService: LocalStorageService,
        private afsDB: FirestoreService,
        private searchService: SearchService,
    ) {
        this.orgId = this.localStorageService.getItemSync('user_organization');
        this.ref = `/organizations/${this.orgId}/institutions`;

        this.localStorageService.getItem('user_organization').subscribe(orgId => {
            this.orgId = orgId;
            this.ref = `/organizations/${this.orgId}/institutions`;
        });
    }

    public async createInstitution(institution: Institution): Promise<any> {
        institution.id = this.afsDB.getNewId();
        return this.afsDB.setDoc(`${this.ref}/${institution.id}`, await this.parseInstitution(institution) as DocItem)
    }

    public async updateInstitution(institution: Institution): Promise<any> {
        return this.afsDB.update(`${this.ref}/${institution.id}`, await this.parseInstitution(institution) as DocItem);
    }

    private async parseInstitution(institution: Institution): Promise<Institution> {
        const newInstitution: Institution = JSON.parse(JSON.stringify(institution));
        return this.timestampDate.parseStringToDate(newInstitution);
    }

    public deleteInstitution(institutionId: string): Promise<any> {
        return this.afsDB.remove(`${this.ref}/${institutionId}`);
    }

    public getInstitutions(queryFn?: any): Observable<Institution[]> {
        return this.afsDB.colWithIds$(this.ref, queryFn);
    }

    public getInstitutionsFromIds(ids: string[]): Observable<Institution[]> {
        return this.afsDB.docsFromId$(this.ref, ids);
    }

    public getInstitutionById(institutionId: string): Observable<Institution> {
        return this.afsDB.docWithId$(`${this.ref}/${institutionId}`);
    }

    public getInstitutionsForWorkingArea(workingAreaId: string): Observable<Institution[]> {
        return this.getInstitutions(ref => ref.where(`workingAreas.${workingAreaId}`, '==', true));
    }

    public getInstitutionsForWorkingAreas(workingAreaIds: string[], type: InstitutionTypes): Observable<Institution[]> {
        let obs: Observable<Institution[]>[];
        if (workingAreaIds?.length) {
            obs = workingAreaIds.map(id => {
                return this.getInstitutionsForWorkingArea(id);
            });
        } else if (type) {
            return this.getInstitutions(ref => ref.where('type', '==', type));
        }

        return combineLatest(obs).pipe(
            map(list => {
                const insts = flatten(list);

                return uniqBy(insts, 'id');
            }),
            distinctUntilChanged()
        );
    }

    public searchInstitutions(query: InstitutionFilter): Observable<Institution[]> {
        const where = this.getFilters(query);
        return new Observable((observer) => {
            this.searchService.searchCollectionIndex({
                searchText: query.textFilter,
                ref: this.ref,
                where
            }).then((institutions: Institution[]) => {
                observer.next(institutions);
            });
        });
    }

    public searchInstitutionsNew(text: string) {
        return this.afsDB.ColWithIdsNoCacheNew<Institution>(this.ref, () => [[`searchIndex.index.${text.toLowerCase()}`, '==', true]])
    }

    public getInstitutionPagination(query: InstitutionFilter) {
        const options: Partial<QueryConstraintOptions<Institution>> = {};
        options.orderBy = [{ field: 'name', val: 'asc' }];
        if (query.limit) options.limit = query.limit;
        return this.afsDB.colWithIdsNew$<Institution>(this.ref, () => this.getFilters(query), options, true)
    }

    public getInstitutionCount(query: InstitutionFilter) {
        return this.afsDB.getCounts(this.ref, () => this.getFilters(query));
    }

    public getInstitutionByFilter(query: InstitutionFilter, limit?: number, lastOrFirstRef?: any, isForwardNavigation?: boolean, isReloadedContext?: boolean) {
        const options: Partial<QueryConstraintOptions<Institution>> = {};
        if (lastOrFirstRef) {
            options.orderBy = [{ field: 'name', val: 'asc' }]
            if (isReloadedContext) {
                options.startAt = lastOrFirstRef;
                options.limit = limit || 30;
            } else {
                if (isForwardNavigation) {
                    options.startAfter = lastOrFirstRef;
                    options.limit = limit || 30;
                } else {
                    options.endBefore = lastOrFirstRef;
                    options.limitToLast = limit || 30;
                }
            }
        } else {
            options.limit = limit || 30
        }
        return this.afsDB.colWithIdsNew$<Institution>(this.ref, () =>  this.getFilters(query), options, true);
    }

    private getFilters(query: InstitutionFilter) {
        const filter: Where[] = [];
        if (query.typeFilter) {
            filter.push(['type', '==', query.typeFilter]);
        }
        if (query.workingAreaFilter) {
            filter.push([`workingAreas.${query.workingAreaFilter}`, '==', true])
        }
        return filter;
    }



    // contact person in institution
    // public getContactPersonsForInstitution(institutionId: string): Observable<InstitutionContactPerson[]> {
    //     return this.afsDB.colWithIds$(`${this.ref}/${institutionId}/contacts`);
    // }

    public createContactPerson(contact: InstitutionContactPerson): Promise<any> {
        return this.afsDB.add(`${this.ref}/${contact.institutionId}/contacts`, contact);
    }

    public deleteContactPerson(contact: InstitutionContactPerson): Promise<any> {
        return this.afsDB.remove(`${this.ref}/${contact.institutionId}/contacts/${contact.id}`);
    }

    public updateContactPerson(contact: InstitutionContactPerson): Promise<any> {
        return this.afsDB.update(`${this.ref}/${contact.institutionId}/contacts/${contact.id}`, contact as DocItem);
    }
}
