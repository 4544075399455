<div fxLayout="column">
    <mat-progress-bar style="margin-bottom: 10px;" mode="indeterminate" [style.opacity]="isLoading ? 1 : 0"></mat-progress-bar>
    <div [class]="!isMobile ? 'form-row' : ''">
        <div [style]="{width: isMobile ? '100%' : '50%'}">
            <mat-form-field class="postal-code" appearance="outline" [style]="{width: isMobile ? '100%' : '46%'}">
                <mat-label>{{labels.postalcode}}</mat-label>
                <input autocomplete="nope" matInput [(ngModel)]="postalCode" [disabled]="selectedCountry != 'NLD'" (keyup)="postalCodeInput('postalCode')"
                    pattern="[0-9]{4}[A-Za-z]{2}" mask="0000SS">
            </mat-form-field>
            <mat-form-field appearance="outline"
                [style]="{width: isMobile ? '48%' : '23%', 'margin-right': isMobile ? '5px': ''}">
                <mat-label>{{labels.nr}}</mat-label>

                <input  mask="0*" #houseNumberInput [(ngModel)]="houseNumber" matInput (keyup)="postalCodeInput('houseNumber')"
                    (blur)="fetchAddressByPostalCode()" [disabled]="selectedCountry != 'NLD'"
                    class="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none">
            </mat-form-field>
            <mat-form-field appearance="outline"
                [style]="{width: isMobile ? '48%' : '23%', 'margin-left': isMobile ? '5px': ''}">
                <mat-label>{{labels.letter}}</mat-label>
                <mat-select #letter [(ngModel)]="houseLetter" [disabled]="letters?.length === 0" [disabled]="selectedCountry != 'NLD'">
                    <mat-option *ngFor="let letter of letters" [value]="letter" (onSelectionChange)="selectLetter(letter)">
                        {{letter}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    
        <div [style]="{width: isMobile ? '100%' : '50%'}">
            <mat-form-field appearance="outline" style="display: flex;" class="search">
                <mat-label>{{labels.search}}</mat-label>
                <input [disabled]="!countries?.length" type="text" #addressSearch
                    (input)="searchAddress(addressSearch.value)" matInput [matAutocomplete]="auto"
                    [placeholder]="labels.search_address_placeholder" autocomplete="off">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayAddress">
                    <mat-option *ngFor="let address of matchedAddress?.matches || []" [value]="address"
                        (onSelectionChange)="selectAddress(address)">
                        {{address.value}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
    
    <div class="form-row country">
        <div class="fs-1-2 mb-4" style="margin-left: 5px; text-wrap: wrap;">{{addressStringLine1}}<br>{{addressStringLine2}}</div>
    
        <div [style]="{width: isMobile ? '100%' : '50%','margin-left': 'auto'}">
            <mat-form-field appearance="outline" style="display: flex;" class="country">
                <mat-label>{{labels.country}}</mat-label>
                <mat-select [(value)]="selectedCountry">
                    <mat-option *ngFor="let country of countries" [value]="country.iso3">
                        {{country.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>