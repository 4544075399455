import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { RouterService, LocalStorageService } from 'app/core';
import { IAreaItem, Institution, InstitutionInfoItem, MenuItem, Municipality, MunicipalityDistrict, Organization, RequestResult, Role, Service, UsageActionTypes, User, UserInfoItem, WorkingArea } from 'app/shared/model';
import {
  OrgServiceService, RequestService,
  ISearchRequestsQuery,
  UserPromptsService,
  UserService,
  LabelService,
  UtilitiesService,
  OrganizationService,
  UsageService,
  SearchService,
} from '@core/services';
import { Subscription, Observable } from 'rxjs';
import { BaseComponent } from '@shared/BaseComponent';
import { RequestStatus, RoleTypes } from 'app/shared/enum';
import { Request } from 'app/shared/model';
import { IObjectMap } from '@shared/interface';
import { isEqual, map, orderBy, sortBy, uniqBy } from 'lodash';
import moment from 'moment';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { ExportRequestsComponent } from '../export-requests/export-requests.component';
import { take, takeUntil } from 'rxjs/operators';
import { NgSub } from 'ng-sub';
import { ChecklistModalComponent } from '@shared/entry-components/checklist-modal/checklist-modal.component';
import { SearchPersonComponent } from '@shared/entry-components/search-person/search-person.component';
import { getYear, isPast } from 'date-fns';
import { ActivatedRoute, Router } from '@angular/router';
import { NewRequestFormComponent } from '@shared/entry-components/new-request-form/new-request-form.component';
import { ProductService } from '@core/services/product.service';
import { PageEvent } from '@angular/material/paginator';
import { AppStore } from '@core/store';
import { MunicipalityService } from '@core/services/municipality/municipality.service';
import { InstitutionSelectorComponent } from '../request-page/institution-selector/institution-selector.component';
import { ContactPersonSelectorComponent } from '../request-page/contact-person-selector/contact-person-selector.component';
import { RequestUsersInfoDialogComponent } from '../../../shared/components/request-users-info-dialog/request-users-info-dialog.component';

enum FilterTypes {
  service = 1,
  // ... we are not interested in others yet just service filter for now
}

export interface RequestFilter {
  textFilter?: string;
  statusFilter?: number;
  executorFilter?: string;
  coordinatorFilter?: string;
  workingAreaFilter?: string;
  serviceFilter?: string;
  activeInYearFilter?: string | number;
  activeInMonthFilter?: string[];
  kindFilter?: string;
  productFilter?: string;
  limit?: number,
  districtFilter?: string,
  neighborhoodFilter?: string,
  referredByFilter?: string;
  resultFilter?: string;
  municipalityFilter?: Municipality,
  publishedFilter?: boolean;
  signalFilter?: boolean;
  overDueFilter?: boolean;
  referredByContactPerson?: UserInfoItem;
  referredByInstitution?: InstitutionInfoItem;
  teamMemberFilter?: boolean,
  onlyTeamMemberFilter?: boolean
}


@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('customChecklistBtnBody', { static: true }) customChecklistBtnBody: TemplateRef<any>;

  public tableData: IObjectMap<any>[] = [];
  public currentYear = getYear(new Date());
  public yearsList: number[] = [];
  public monthNames: { id: number; name: string; }[];
  public kindsList: any[] = [];
  public productsList: any[] = [];
  public requestsList: Request[];
  public screenBreakpoints: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
  public isExtraSmall = false;
  public isLoading = false;
  public FilterTypes = FilterTypes;
  public selectedService: Service;
  public selectedWorkingArea: WorkingArea;
  public requestStatuses = [
    { key: 1, value: 'waitingList' },
    { key: 2, value: 'intake' },
    { key: 3, value: 'assigned' },
    { key: 4, value: 'inProgress' },
    { key: 5, value: 'closed' },
    { key: 6, value: 'adminReady' },
    { key: 7, value: 'rejected' }
  ];
  public RequestStatus = RequestStatus;
  public requestFilter: RequestFilter = {};
  public districts: MunicipalityDistrict[];
  public isAllEmpty: boolean;
  // public municipalitySearchText: any;
  // public search$ = new Subject<number>();
  public advanceFilterEnabled = false;
  public workingAreas: WorkingArea[] = [];
  public services: Service[] = [];
  public coordinators: User[] = [];
  public executors: User[] = [];
  public municipalities: Municipality[];
  public labels = this.labelService.defaultProvider();
  public defaultStatuses: RequestStatus[] = [];
  public userRole: Role;
  public canFetchMore = true;
  public requestResult: { name: RequestResult; id: number }[];
  private sub = new NgSub();
  private org: Organization;
  private requestsListSubscription = new Subscription();
  //private lastDoc: any;
  isSearchButtonDisabled: boolean;
  public paginationOptions = this.resetPaginatorOptions();
  private count: number;
  public dataTableConfig: IObjectMap<any>[] = [];
  private searchTextUsersSubscription: Subscription;
  private appStoreRequestListingState = 'request_last_filter_state_before_navigating_away';
  public canClearText: boolean = false;
  protected fetchingMunicipalities = false;
  private timeout: NodeJS.Timeout;

  constructor(
    private localStorageService: LocalStorageService,
    private labelService: LabelService,
    private routerService: RouterService,
    private userPromptsService: UserPromptsService,
    private requestsService: RequestService,
    private userService: UserService,
    private orgServiceService: OrgServiceService,
    private utilitiesService: UtilitiesService,
    public breakpointObserver: BreakpointObserver,
    private orgService: OrganizationService,
    private router: Router,
    private route: ActivatedRoute,
    private usageService: UsageService,
    private searchService: SearchService,
    private productService: ProductService,
    private municipalityService: MunicipalityService,
  ) {
    super();

    this.subscriptions.push(this.screenBreakpoints.subscribe((result) => {
      this.isExtraSmall = result.matches;
    }));
  }

  async ngOnInit() {
    this.localStorageService.getItem('user_role').subscribe((role) => {
      if (role) {
        this.userRole = this.userService.getCurrentUserRole();
        this.fetchManagementUsers();
        this.clearFilters();
      }
    });

    this.labels = (await this.labelService.getLabels('app-requests')).data;
    this.defaultStatuses = this.utilitiesService.getKeysForEnum(RequestStatus);

    const menuItem: MenuItem = Object.assign({}, new MenuItem(), {
      title: this.labels.service_requests,
      searchEnabled: false
    });

    this.routerService.setMainMenuItem(menuItem);
    const state: { requestFilter: RequestFilter; paginationOptions: any } = AppStore.getDataSync(this.appStoreRequestListingState);
    if (state) {
      this.requestFilter = state.requestFilter;
      this.paginationOptions = state.paginationOptions;
      // const fromIndex = this.paginationOptions.pageIndex * this.paginationOptions.requestsPerPage;
      // const toIndex = (this.paginationOptions.pageIndex + 1) * this.paginationOptions.requestsPerPage;
      this.fetchRequestsUsingFilter(true, true);
    } else {
      this.fetchRequestsForFirstLoad();
      this.requestsService.getRequestCount(this.requestFilter).then(res => {
        this.count = res;
        this.paginationOptions.totalSize = this.count
      });
    }

    this.labelService.getMonthNames().then(m => {
      this.monthNames = m.map(a => {
        a.id++;
        return a;
      });
    });

    for (let y = this.currentYear; y >= 2018; y--) {
      this.yearsList.push(y);
    }

    this.productService.getList().subscribe(products => {
      this.productsList = products;
    });

    this.orgService.getCurrentOrganization().pipe(takeUntil(this.sub)).subscribe({
      next: org => {
        this.org = org;
        this.updateTableData();
        if (this.org?.municipalities?.length) {
          this.municipalities = orderBy(this.org.municipalities, 'text', 'asc');
          this.getDistricts();
        }
      },
      error: e => console.log(e)
    });
    this.requestResult = this.utilitiesService.getKeysForEnum(RequestResult).map((n) => {
      return {
        name: this.labels[RequestResult[n]],
        id: n
      }
    })
  }

  private createRequest(user: User): void {
    this.userPromptsService.showDialogue(NewRequestFormComponent, {
      customer: user
    }, null, false);
  }

  public openAddUserDialog(): void {
    const data = {};

    this.userPromptsService.showDialogue(SearchPersonComponent, data, (user: User) => {
      if (user) {
        this.createRequest(user);
      }
    }, true);
  }

  public canShowChecklistIcon(serviceId: string): boolean {
    const s = this.services.find(d => d.id === serviceId);

    return s?.settings.defaultChecklist?.activated;
  }

  private async fetchManagementUsers() {
    this.workingAreas = [this.userRole.workingArea];

    if (this.userRole.roleType === RoleTypes.coordinator) {
      this.fetchExecutorsForService();
      this.fetchCoordinatorsForService();
      this.services = [this.userRole.service];
      this.requestFilter.workingAreaFilter = this.workingAreas.find(wa => wa?.id == this.userRole.workingAreaId)?.id
      this.selectedService = this.services.find(s => s.id === this.userRole.serviceId);
      this.selectedWorkingArea = this.workingAreas.find(wa => wa.id == this.userRole.workingAreaId);
      // todo this.selectedWorkingArea?.settings.referredByValues.sort();
      this.requestFilter.serviceFilter = this.selectedService.id;
    }

    if (this.userRole.roleType === RoleTypes.professional) {
      this.selectedWorkingArea = this.workingAreas.find(wa => wa.id == this.userRole.workingAreaId);
      // todo this.selectedWorkingArea?.settings.referredByValues.sort();
      this.requestFilter.workingAreaFilter = this.selectedWorkingArea.id;
      const services: Service[] = await this.orgServiceService.getServicesNoCache(true, this.userRole.workingAreaId);
      this.services = sortBy(services, 'name').filter(s => !!s);
      this.fetchExecutorsForWorkingarea();
      this.fetchCoordinatorsForWorkingarea();
    }

    if (this.userRole.roleType == RoleTypes.servicePoint) {
      this.selectedWorkingArea = this.workingAreas[0];
      this.requestFilter.workingAreaFilter = this.selectedWorkingArea.id;
    }

    this.getKind();
  }

  private getKind() {
    if (this.workingAreas.length) {
      this.kindsList = this.workingAreas.find(wa => wa?.id === this.userRole.workingAreaId)?.settings.requestKind;
    }
  }

  private fetchExecutorsForService(serviceId?: string) {
    this.userService.getExecutorsForService(serviceId || this.userRole.serviceId, true).pipe(take(1)).subscribe(users => {
      this.executors = sortBy(users, 'firstname');
    });
  }

  private fetchExecutorsForWorkingarea() {
    this.userService.getExecutorsForWorkingArea(this.userRole.workingAreaId, true).pipe(take(1)).subscribe(users => {
      this.executors = sortBy(users, 'firstname');
    });
  }

  private fetchCoordinatorsForService(serviceId?: string) {
    this.userService.getCoordinatorsForService(serviceId || this.userRole.serviceId, true).pipe(take(1)).subscribe(users => {
      this.coordinators = sortBy(users, 'firstname');
    });
  }

  private fetchCoordinatorsForWorkingarea() {
    this.userService.getCoordinatorsForWorkingArea(this.userRole.workingAreaId, true).pipe(take(1)).subscribe(users => {
      this.coordinators = sortBy(users, 'firstname');
    });
  }

  public async fetchRequestsForFirstLoad() {
    this.isLoading = true;
    this.requestFilter.limit = 30;
    this.requestsListSubscription = this.requestsService.getRequestsNew(this.requestFilter).subscribe(requests => {
      this.requestsList = requests;
      if (this.requestsList.length) {
        this.paginationOptions.lastRef = (this.requestsList[this.requestsList.length - 1] as any)['__doc'];
        this.paginationOptions.firstRef = (this.requestsList[0] as any)['__doc'];
        this.paginationOptions.requestsPerPage = 30;
        this.updateTableData();
        this.loadData(0, 30);
      } else {
        this.getTableConfig([]);
        this.updateAllEmpty();
      }
    });
  }

  private loadData(fromIndex: number, toIndex: number) {
    this.getTableConfig(this.requestsSubset(fromIndex, toIndex));
  }

  public updateTableData(): void {
    this.tableData = map(this.requestsList, (request: Request) => {
      const daysIn = request.log && request.log.createdAt
        ? moment().diff(moment(request.log.createdAt), 'days') : 0;
      const isPassed = request?.activity?.firstScheduledActivity ? isPast(request?.activity?.firstScheduledActivity) : false;
      return {
        _statusValue: { val: request.status, org: this.org, innerColor: isPassed ? 7 : 0 },
        customer: request.management.customer?.firstname ?
          `${request.management.customer?.firstname} ${request.management.customer?.lastname}` : '',
        title: request?.published ? request.title += ` (${this.labels.published})` : request.title,
        phone: request.management.customer?.phone ? request.management.customer?.phone : '',
        daysIn: daysIn,
        team: request.management.team ? Object.values(request.management.team).map((member: any) => `${member.firstname} ${member.lastname}`).join(', ') : '',
        picture: request.management.executor ? request.management.executor.picture : undefined,
        executor: request.management.executor
          ? `${request.management.executor.firstname} ${request.management.executor.lastname}` : '',
        originalRequest: request,
        status: request.status,
      };
    });
  }

  public getStatuses(): RequestStatus[] {
    return this.selectedService
      ? this.selectedService.settings.defaultStatusList
      : this.defaultStatuses;
  }

  private updateAllEmpty(): void {
    if (this.isAllEmpty === undefined) {
      this.isAllEmpty = this.requestsList.length === 0;
    }
  }

  openRequestForm(request: Request) {

    // save state here
    const state = {
      requestFilter: this.requestFilter,
      paginationOptions: this.paginationOptions
    };
    AppStore.setData(this.appStoreRequestListingState, state);
    this.router.navigate([`/${this.org.id}/dashboard/requests/detail`, request.id], { relativeTo: this.route })
  }

  handleRowSelect(res) {
    this.openRequestForm(res.originalRequest);
    // update seen user
    const request = res.originalRequest as Request;
    const userInfo = {
      userId: request.management.customerId,
      firstName: request.management.customer.firstname,
      lastName: request.management.customer.lastname
    };
    this.usageService.addActivity(UsageActionTypes.view_request, userInfo);
  }

  public openChecklistDialog(request: Request): void {
    this.userPromptsService.showDialogue(ChecklistModalComponent, {
      request
    }, null, false);
  }

  public checklistBtnColor(row: any): string {
    const r = row.originalRequest as Request;
    const unchecked = (r.checklistItems || []).find(c => !c.checked);

    if ((r.checklistItems || []).length === 0) {
      return 'text-grey';
    }

    if ((r.status === RequestStatus.adminReady || r.status === RequestStatus.closed) && unchecked) {
      return 'text-warn';
    }

    if (unchecked) {
      return '';
    } else {
      return 'text-primary';
    }
  }

  public getTableConfig(data: IObjectMap<any>[]) {
    this.dataTableConfig = data as IObjectMap<any>[];
    this.isLoading = false;
  }

  checkTextFilterValid() {
    if (!this.requestFilter.textFilter) {
      this.isSearchButtonDisabled = false;
      this.handleFilterSubmit();
    }
    if (this.requestFilter.textFilter && this.requestFilter.textFilter.length > 0 && this.requestFilter.textFilter.length <= 1) {
      this.isSearchButtonDisabled = true;
    } else {
      this.isSearchButtonDisabled = false;
    }
  }

  public handleFilterSubmit() {
    setTimeout(() => {
      if (this.isSearchButtonDisabled) {
        return;
      }
      this.requestsListSubscription?.unsubscribe();
      this.requestsList = [];
      if (!this.requestFilter.textFilter || this.requestFilter?.textFilter?.length >= 3) this.getTableConfig([]);
      this.paginationOptions = this.resetPaginatorOptions();
      this.updateFilterBeforeFetch();
      this.fetchRequestsUsingFilter(true, false);
    }, 0);
  }

  private updateFilterBeforeFetch() {
    if (!this.requestFilter.teamMemberFilter && this.requestFilter.onlyTeamMemberFilter) {
      this.requestFilter.onlyTeamMemberFilter = false;
    }
  }

  private resetPaginatorOptions() {
    return {
      totalSize: this.count,
      requestsPerPage: 30,
      pageIndex: 0,
      lastRef: null as any,
      firstRef: null as any
    };
  }


  public async exportRequests() {
    const payload: any = {};
    payload.searchQuery = this.getQuery();

    if (this.isFilterSetForExport()) {
      payload.requests = this.getExportData();
    } else {
      if (this.userRole.roleType == RoleTypes.professional || this.userRole.roleType == RoleTypes.coordinator) {
        let requests = await this.requestsService.getRequestForExport(this.requestFilter.workingAreaFilter, this.requestFilter.serviceFilter);
        requests = orderBy(requests, ['status', 'log.createdAt'], ['asc', 'desc']);
        payload.requests = requests;
      }
    }
    this.userPromptsService.showDialogue(ExportRequestsComponent, payload);
  }

  private getQuery() {
    const obj = {
      search: this.requestFilter.textFilter,
      serviceId: this.requestFilter.serviceFilter,
      workareaId: this.requestFilter.workingAreaFilter,
      coordinatorId: this.requestFilter.coordinatorFilter,
      executorId: this.requestFilter.executorFilter,
      district: this.requestFilter.districtFilter,
      neighborhood: this.requestFilter.neighborhoodFilter,
      status: this.requestFilter.statusFilter,
      // municipality: this.municipality,
      activeinYear: this.requestFilter.activeInYearFilter,
      referredBy: this.requestFilter.referredByFilter,
      result: this.requestFilter.resultFilter,
      isClosed: null
    } as ISearchRequestsQuery;
    return obj;
  }

  private getExportData() {
    if (this.isFilterSetForExport()) {
      return this.requestsList;
    }
    return [];
  }

  private isFilterSetForExport() {
    if ((this.requestFilter.executorFilter)
      || (this.requestFilter.coordinatorFilter)
      || (this.requestFilter.activeInYearFilter)
      || (this.requestFilter.activeInMonthFilter?.length)
      || (this.requestFilter.productFilter)
      || (this.requestFilter.kindFilter)
      || (this.requestFilter.statusFilter)
      || (this.requestFilter.textFilter)
    ) return true;
    else return false;
  }

  public clearFilters() {
    this.requestFilter.textFilter = '';
    this.requestFilter.coordinatorFilter = null;
    this.requestFilter.executorFilter = null;
    this.requestFilter.statusFilter = null;
    this.requestFilter.kindFilter = null;
    this.requestFilter.activeInMonthFilter = [];
    this.requestFilter.activeInYearFilter = null;
    this.requestFilter.productFilter = null;
    this.requestFilter.districtFilter = null;
    this.requestFilter.neighborhoodFilter = null;
    this.requestFilter.referredByFilter = null;
    this.requestFilter.resultFilter = null;
    this.requestFilter.municipalityFilter = null;
    this.requestFilter.publishedFilter = false;
    this.requestFilter.signalFilter = false;
    this.requestFilter.overDueFilter = false;
    this.requestFilter.referredByInstitution = null;
    this.requestFilter.referredByContactPerson = null;
    if (this.userRole.roleType !== RoleTypes.coordinator) {
      this.requestFilter.serviceFilter = null;
    }
    this.requestsList = [];
    this.isSearchButtonDisabled = false;
    this.paginationOptions = this.resetPaginatorOptions();
    this.requestsListSubscription?.unsubscribe();
    this.fetchRequestsForFirstLoad();
    this.requestsService.getRequestCount(this.requestFilter).then(res => {
      this.count = res;
      this.paginationOptions.totalSize = this.count
    });
  }

  public onPaginationChange(evt: PageEvent): void {
    const newFromIndex = evt.pageIndex * evt.pageSize;
    const newToIndex = (evt.pageIndex + 1) * evt.pageSize;
    if (newFromIndex < this.requestsList.length && newToIndex < this.requestsList.length) {
      this.paginationOptions.requestsPerPage = evt.pageSize;
      this.paginationOptions.pageIndex = evt.pageIndex;
      this.getTableConfig(this.requestsSubset(newFromIndex, newToIndex));
    } else {
      this.paginationOptions.pageIndex = evt.pageIndex;
      this.paginationOptions.requestsPerPage = evt.pageSize;
      this.fetchRequestsUsingFilter(evt.previousPageIndex <= evt.pageIndex, false); //= is for resize of page count scenario
    }
  }

  public anyNonSearchTextFiltersSet() {
    if ((this.requestFilter.executorFilter)
      || (this.requestFilter.coordinatorFilter)
      || (this.requestFilter.activeInYearFilter)
      || (this.requestFilter.activeInMonthFilter.length)
      || (this.requestFilter.productFilter)
      || (this.requestFilter.kindFilter)
      || (this.requestFilter.statusFilter)
      || ((this.requestFilter.serviceFilter) && this.userRole.roleType !== RoleTypes.coordinator)
      || (this.requestFilter.districtFilter)
      || (this.requestFilter.neighborhoodFilter)
      || (this.requestFilter.referredByFilter)
      || (this.requestFilter.resultFilter)
      || (this.requestFilter.municipalityFilter)
      || (this.requestFilter.publishedFilter)
      || (this.requestFilter.signalFilter)
      || (this.requestFilter.overDueFilter)
      || (this.requestFilter.referredByContactPerson)
      || (this.requestFilter.referredByInstitution)
    ) {
      return true;
    } else {
      return false;
    }
  }

  public requestsSubset(from: number, to: number): IObjectMap<any>[] {
    return this.tableData.filter(r => !!r).slice(from, to);
  }

  private async fetchRequestsUsingFilter(isForwardNavigation: boolean, isReloadedContext: boolean) {
    const opts = this.paginationOptions;

    const fromIndex = opts.pageIndex * opts.requestsPerPage;
    const toIndex = (opts.pageIndex + 1) * opts.requestsPerPage;

    if (this.anyNonSearchTextFiltersSet()) { //any non-search text filter is set
      this.isLoading = true;
      if (this.requestFilter.textFilter) {
        this.searchTextUsersSubscription = this.requestsService.searchRequestsNew(this.requestFilter).subscribe(requests => {
          this.requestsList = requests

          const filteredRequests: any[] = this.searchService.searchListBySearchIndex(requests, this.requestFilter.textFilter); //returns based on relevancy
          this.requestsList = orderBy(uniqBy(filteredRequests.concat(requests), 'id'), ['countMatch', 'status', 'log.createdAt'], ['desc', 'asc', 'asc']);

          this.updateAllEmpty();
          this.updateTableData();
          this.paginationOptions.totalSize = this.requestsList.length;
          this.loadData(fromIndex, toIndex);
        })
      } else {
        this.requestsListSubscription = this.requestsService.getAllRequestsByFIlter(this.requestFilter).subscribe(requests => {
          this.requestsList = requests;
          this.requestsList = this.orderRequests(requests)
          this.updateAllEmpty();
          this.updateTableData();
          this.paginationOptions.totalSize = this.requestsList.length;
          this.loadData(fromIndex, toIndex);
        })
      }

      this.sub.add(this.requestsListSubscription);
    } else if (this.requestFilter.textFilter) { //only search text filter is present
      if (this.requestFilter.textFilter.length >= 3) {
        this.isLoading = true;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchTextUsersSubscription = this.requestsService.searchRequestsNew(this.requestFilter).subscribe(requests => {
            const filteredRequests: any[] = this.searchService.searchListBySearchIndex(requests, this.requestFilter.textFilter); //returns based on relevancy
            //todo 'any' has to be 'request' . remove countMatch after order by and then assign to 
            this.requestsList = orderBy(uniqBy(filteredRequests.concat(requests), 'id'), ['countMatch', 'status', 'log.createdAt'], ['desc', 'asc', 'asc']);

            this.paginationOptions.totalSize = this.requestsList.length;
            this.updateAllEmpty();
            this.updateTableData();
            this.getTableConfig(this.requestsSubset(fromIndex, toIndex));
            // this.isLoading = false;
          });
          this.sub.add(this.searchTextUsersSubscription);
        }, 1000);
      }
    } else { //no filter is present
      this.isLoading = true;
      this.count = await this.requestsService.getRequestCount(this.requestFilter);
      this.requestsListSubscription?.unsubscribe();
      if (isForwardNavigation) {
        this.requestsListSubscription = this.requestsService
          .getRequestsByFilters(this.requestFilter, opts.requestsPerPage,
            isReloadedContext ? this.paginationOptions.firstRef : this.paginationOptions.lastRef, isForwardNavigation, isReloadedContext)
          .subscribe(requests => {
            if (!isReloadedContext) { //to prevent 'no users found' displayed on navigating back from user details page
              this.requestsList = [];
            }
            if (requests?.length) {
              this.requestsList = this.orderRequests(requests);
              this.paginationOptions.lastRef = (this.requestsList[this.requestsList.length - 1] as any)['__doc'];
              this.paginationOptions.firstRef = (this.requestsList[0] as any)['__doc'];
              this.paginationOptions.totalSize = this.count;
              this.updateTableData();
              this.getTableConfig(this.tableData);
            }
          });
      } else {
        console.log(opts)
        this.requestsListSubscription = this.requestsService
          .getRequestsByFilters(this.requestFilter, opts.requestsPerPage, this.paginationOptions.firstRef, isForwardNavigation, false)
          .subscribe(requests => {
            this.requestsList = [];
            if (requests?.length) {
              this.requestsList = this.orderRequests(requests);
              this.paginationOptions.lastRef = (this.requestsList[this.requestsList.length - 1] as any)['__doc'];
              this.paginationOptions.firstRef = (this.requestsList[0] as any)['__doc'];
              this.paginationOptions.totalSize = this.count;
              this.updateTableData();
              this.getTableConfig(this.tableData);
            }
          });
      }
      this.sub.add(this.requestsListSubscription);
    }
  }

  private orderRequests(requests: Request[]) {
    return orderBy(requests, ['status', 'log.updatedAt'], ['asc', 'desc']);
  }

  public getServices() {
    if (this.requestFilter.serviceFilter) {
      this.fetchExecutorsForService(this.requestFilter.serviceFilter);
      this.fetchCoordinatorsForService(this.requestFilter.serviceFilter);
      return;
    }
    this.fetchManagementUsers()
  }

  public searchAuto() {
    this.handleFilterSubmit()
  }


  public advance() {
    this.advanceFilterEnabled = !this.advanceFilterEnabled;
    if (!this.advanceFilterEnabled) {
      this.requestFilter.districtFilter = null;
      this.requestFilter.neighborhoodFilter = null;
    }
  }

  public loadMunicipalities(): void {
    this.municipalities = [];
    this.fetchingMunicipalities = true;
    this.sub.add(
      this.municipalityService.getMunicipalities().subscribe(ms => {
        this.municipalities = orderBy(ms, 'text', 'asc');
        this.fetchingMunicipalities = false;
      })
    )
    this.getDistricts();
  }

  public displayMunicipalityName(m: Municipality): string {
    return m?.text || '';
  }

  public filterMunicipalities(): Municipality[] {
    if (!this.municipalities) {
      return [];
    }
    const text = this.requestFilter.municipalityFilter?.text || this.requestFilter.municipalityFilter || '';
    return this.municipalities.filter(m => {
      return m?.text?.toLowerCase().includes((text as any).toLowerCase() || '') || false;
    });
  }


  public onMunicipality(data: any) {
    this.utilitiesService.delay(400).subscribe(() => {
      if (this.municipalities) {
        this.getDistricts();
      }
      this.canClearText = !!data;
      if (!data) {
        this.handleFilterSubmit();
        return;
      }
      if (this.municipalities.indexOf(data) > -1) {
        this.handleFilterSubmit();
      }
    });
  }

  private getDistricts() {
    const ds = this.requestFilter.municipalityFilter?.districts?.map(d => {
      d.neighbourhoods = orderBy(d.neighbourhoods, ['text'], ['asc']);
      return d;
    }) || [];

    const list = orderBy(ds, ['text'], ['asc']);

    if (!isEqual(this.districts, list)) {
      this.districts = list;
      this.requestFilter.districtFilter = '';
    }
  }

  public clearSelection() {
    this.requestFilter.municipalityFilter = null;
    this.handleFilterSubmit();
  }

  public getNeighborhoodList(): IAreaItem[] {
    return this.requestFilter.districtFilter && this.districts?.length ? this.districts.find(d => d.code === this.requestFilter.districtFilter)?.neighbourhoods || [] : [];
  }

  // public async updateActivities() {
  //   const activities = await this.reportingRequestService.getActivitiesForUpdate().pipe(take(1)).toPromise();
  //   console.log(activities.length);
  //   await Promise.all(
  //     activities.map(async (activity, i) => {
  //       if (!activity.id) return;
  //       activity.rates.internal = 1;
  //       if (activity.userId !== 'WkciHuHMeba2WQXZ1xkJ' && activity.userId !== 'l235OHONVQsusQig0E3p'
  //         && activity.userId !== 'O9BbqUZuUsuYKMIB8BAQ' && activity.id != 'TwGZuQ5Qb9gP9Agr80VU'
  //         && activity.userId !== 'j0vLOcQThuQ5P7akTcL4' && activity.userId !== 'DNfb3HWdvB1lrnbyDbio'
  //         && activity.id != 'LinWiRawQ92CzhGWWxtE' && activity.id !== 'MopwGnaiHBg4HrgOTmIN') {
  //         await this.reportingRequestService.updatedActivities(activity);
  //         console.log(`Updated activity: ${activity.id}, Number: ${i + 1}`);
  //       }
  //       return;
  //     }))

  //   console.log('Done updating')
  // }

  protected async openInstitutionAndContactPersonSelector(type: 'referredByInstitution' | 'referredByContactPerson') {
    if (type == 'referredByInstitution') {
      this.userPromptsService.showDialogue(InstitutionSelectorComponent, { workingAreaId: this.userRole.workingAreaId }, (institution: Institution) => {
        if (institution) {
          this.requestFilter.referredByInstitution = {
            id: institution.id,
            logo: institution.logoUrl,
            name: institution.name
          };

          this.requestFilter.referredByContactPerson = null;

          this.handleFilterSubmit();
        }
      })

    } else {
      if (this.requestFilter.referredByInstitution) {
        this.userPromptsService.showDialogue(ContactPersonSelectorComponent, { referredByInstitution: this.requestFilter.referredByInstitution }, (contactPerson: User) => {
          if (contactPerson) {
            this.requestFilter.referredByContactPerson = {
              userId: contactPerson.id,
              firstname: contactPerson.firstname,
              lastname: contactPerson.lastname,
              phone: contactPerson.phone,
              picture: contactPerson.picture,
              // What type of remark is to be used here?
              remark: contactPerson?.phoneRemark || ''
            };;
            this.handleFilterSubmit();
          }
        })
      }
    }
  }

  protected openUsersInfoDialog(request: Request) {
    this.userPromptsService.showDialogue(RequestUsersInfoDialogComponent, { request });
  }

  protected getStatusNum(status: RequestStatus) {
    return `counter_${status}`;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.requestsListSubscription?.unsubscribe();
    if (!this.router.url.includes('request')) {
      AppStore.remove(this.appStoreRequestListingState);
    }
  }

}
