<app-modal>
    <div class="modal_header" #modal_header>
        <div class="modal_title">{{labels.planned_leave}}</div>
    </div>

    <div class="modal_body m-3" #modal_body>
        @if (form) {
        <form [formGroup]="form">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
                <div class="mb-4">
                    <mat-form-field appearance="outline">
                        <mat-label>{{labels.from}}</mat-label>
                        <input matInput [max]="form.value.to" [matDatepicker]="pickerFrom" formControlName="from">
                        <mat-datepicker-toggle matIconSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                        <mat-datepicker #pickerFrom></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="mb-4">
                    <mat-form-field appearance="outline">
                        <mat-label>{{labels.to}}</mat-label>
                        <input matInput [matDatepicker]="pickerTo" [min]="form.value.from" formControlName="to">
                        <mat-datepicker-toggle matIconSuffix [for]="pickerTo"></mat-datepicker-toggle>
                        <mat-datepicker #pickerTo></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{labels.remark}}</mat-label>
                    <input matInput formControlName="remark">
                </mat-form-field>
            </div>
        </form>
        }

    </div>

    <div class="modal_footer" #modal_footer fxLayoutGap="10px">
        <div>
            <button mat-button color="primary" [disabled]="!form?.valid || form?.pristine"
                (click)="save()">{{labels.save}}</button>
        </div>
    </div>
</app-modal>