import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LabelService, OrganizationService, OrgServiceService, RequestService, UsageService, UserPromptsService, UserService, UtilitiesService, WorkingAreaService } from '@core/services';
import { TimeIntervalService } from '@core/services/time-interval.service';
import { RoleTypes } from '@models/enum';
import { IObjectMap } from '@models/interface';
import { Address, IArea, Organization, Request, RequestActivity, RequestDurationTypes, Role, Service, TimeGridCell, UsageActionTypes, User, WorkingArea } from '@models/model';
import { GoTogetherDetails, ModeOfTransport } from '@models/model/go-together';
import { AddressModalComponent } from '@shared/components/address-modal/address-modal.component';
import { addHours, format, startOfDay } from 'date-fns';
import { flatten } from 'lodash';
import { NgSub } from 'ng-sub';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mergeAll, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-new-request-form',
  templateUrl: './new-request-form.component.html',
  styleUrls: ['./new-request-form.component.scss']
})
export class NewRequestFormComponent implements OnInit, OnDestroy {
  public customer: User;
  public labels = this.labelService.defaultProvider();
  public loading = false;
  public form: FormGroup;
  public workingAreas: WorkingArea[] = [];
  public services: Service[] = [];
  public currentArea: WorkingArea;
  public currentService: Service;
  protected goTogetherType: 'fromAddress' | 'toAddress' = 'fromAddress';
  private role: Role;
  private sub = new NgSub();
  protected modeOfTransports: number[];
  protected ModeOfTransport = ModeOfTransport;
  public pickUpTimeList: TimeGridCell[] = [];
  public appointmentTimeList: TimeGridCell[] = [];
  public returnTimeList: TimeGridCell[] = [];
  public occupiedTimes: IObjectMap<boolean> = {};
  protected org: Organization;
  protected distanceMatrix: {
    distance: { text: string; value: number }
    duration: { text: string; value: number }
  } = {} as any;
  private goTogetherDetailsFields = ['fromAddress', 'toAddress', 'scheduledForDate', 'pickupTime', 'modeOfTransport'];
  private addressMap = { fromAddress: null, fromArea: null, toAddress: null, toArea: null };
  protected defaultRequestTitles: string[] = [];
  protected requestDurationTypes: number[] = [];
  protected RequestDurationTypes = RequestDurationTypes;
  protected isRequestDurationEditable = false;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { customer: User },
    public dialogRef: MatDialogRef<NewRequestFormComponent>,
    private labelService: LabelService,
    private fb: FormBuilder,
    private workingAreaService: WorkingAreaService,
    private userService: UserService,
    private usageService: UsageService,
    private requestService: RequestService,
    private orgService: OrganizationService,
    private router: Router,
    private route: ActivatedRoute,
    private serviceService: OrgServiceService,
    private utilitiesService: UtilitiesService,
    private timeIntervalService: TimeIntervalService,
    private userPromptsService: UserPromptsService,
  ) { }

  ngOnInit(): void {
    this.timeIntervalService.setInterval(15);
    this.pickUpTimeList = this.timeIntervalService.generateDateCells(new Date());
    this.modeOfTransports = this.utilitiesService.getKeysForEnum(ModeOfTransport);
    this.requestDurationTypes = this.utilitiesService.getKeysForEnum(RequestDurationTypes);
    this.dialogRef.disableClose = true;
    this.role = this.userService.getCurrentUserRole();
    this.customer = this.data.customer;
    this.addressMap.fromAddress = this.customer.address;
    this.orgService.getCurrentOrganization().pipe(take(1)).toPromise().then(org => this.org = org);
    this.createForm();
    this.labelService.getLabels('app-new-request-form').then(res => this.labels = res.data);

    let workingAreas$: Observable<WorkingArea[]>;
    if (this.role.workingArea) {
      workingAreas$ = of([this.role.workingArea]);
      this.form.get('workareaId').setValue(this.role.workingArea.id);
    } else if (this.role.workingAreaId) {
      workingAreas$ = this.workingAreaService.getWorkingAreaById(this.role.workingAreaId).pipe(map(w => [w]));
      this.form.get('workareaId').setValue(this.role.workingAreaId);
    } else {
      workingAreas$ = this.workingAreaService.getWorkingAreas();
    }

    workingAreas$.pipe(
      map(ws => {
        return combineLatest([
          of(ws),
          combineLatest(ws.map(w => this.serviceService.getServices(true, w.id))),
        ]);
      }),
      mergeAll(),
      takeUntil(this.sub),
    ).subscribe(arr => {
      this.workingAreas = arr[0];
      this.services = flatten(arr[1]);
    });
  }

  private createForm(): void {
    this.form = this.fb.group({
      title: ['', Validators.required],
      requestDurationType: [null, Validators.required],
      description: [''],
      workareaId: ['', Validators.required],
      serviceId: ['', Validators.required],
      referredBy: [''],
      referredByNote: [''],
      requestKind: [''],
      goTogetherDetails: this.fb.group({
        fromAddress: [this.contactAddressStr(this.customer.address)],
        toAddress: [null],
        scheduledForDate: [new Date()],
        pickupTime: [9],
        appointmentTime: [],
        returnTime: [],
        modeOfTransport: [this.currentService?.settings?.goTogether?.defaultModeOfTransport],
        roundtrip: []
      })
    });
    this.disabledFields(['goTogetherDetails.returnTime'], false);
    this.updateAppointmentTimeList();
    this.updateReturnTimeList();
    this.listenToFormChanges();
  }

  private contactAddressStr(address: Address) {
    return `${address?.street || ''} ${address?.number || ''} ${address?.letter || ''} ${address?.postalcode || ''}  ${address?.city || ''}`;
  }

  private getRequestDuration() {
    const crtl = this.form.get('requestDurationType');
    const catalog = this.currentService.settings.catalog;
    this.isRequestDurationEditable = catalog.requestDurationTypeEditable == null ? true : catalog.requestDurationTypeEditable;
    if (catalog.defaultRequestDurationType) {
      crtl.setValue(this.currentService.settings.catalog.defaultRequestDurationType);
    } else {
      crtl.setValue(RequestDurationTypes.ongoing);
    }
  }

  private updateAppointmentTimeList() {
    this.appointmentTimeList = this.timeIntervalService.updateEndDateList(this.pickUpTimeList, { form: this.form, prop: 'goTogetherDetails.pickupTime' }, this.occupiedTimes);
  }

  private updateReturnTimeList() {
    this.returnTimeList = this.timeIntervalService.updateEndDateList(this.pickUpTimeList, { form: this.form, prop: 'goTogetherDetails.pickupTime' }, this.occupiedTimes);
  }

  private listenToFormChanges() {
    this.form.get('workareaId').valueChanges.pipe(
      takeUntil(this.sub),
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(id => {
      this.currentArea = this.workingAreas?.find(w => w.id === id);
      this.currentArea?.settings.referredByValues.sort();

      this.form.get('serviceId').setValue('');
    });

    this.form.get('serviceId').valueChanges.pipe(
      takeUntil(this.sub),
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(id => {
      if (id) {
        const s = this.getServices(this.form.value.workareaId).find(s => s.id === id);
        this.currentService = s;
        this.setDefaultTitles();
        this.getRequestDuration();
        this.checkIfFieldsAreRequired();
        if (this.currentService?.settings?.goTogether?.defaultModeOfTransport) {
          this.form.get('goTogetherDetails.modeOfTransport').setValue(this.currentService?.settings?.goTogether?.defaultModeOfTransport);
        }
      }
    });

    this.sub.add(
      this.form.get('goTogetherDetails.pickupTime').valueChanges.subscribe(() => {
        this.updateAppointmentTimeList();
        this.updateReturnTimeList();
      }),
      this.form.get('goTogetherDetails.appointmentTime').valueChanges.subscribe(() => {
        this.updateReturnTimeList();
      })
    );

    this.sub.add(
      this.form.get('goTogetherDetails.roundtrip').valueChanges.subscribe(value => {
        this.disabledFields(['goTogetherDetails.returnTime'], value);
        this.form.get('goTogetherDetails.returnTime').setValue('');
      })
    )
  }

  private setDefaultTitles() {
    this.defaultRequestTitles = (this.currentService?.settings?.placeholders?.defaultRequestTitles || [])
      .concat(this.currentService.settings.subServices || []);
  }

  protected updateModeOfTransport() {
    this.getDistanceMatrix()
  }

  private checkIfFieldsAreRequired() {
    this.goTogetherDetailsFields.forEach(field => {
      const ctl = this.form.get(`goTogetherDetails.${field}`);
      if (this.currentService?.settings?.goTogether?.activated) {
        if (!ctl.hasValidator(Validators.required)) {
          ctl.setValidators(Validators.required);
        }
      } else {
        if (ctl.hasValidator(Validators.required)) {
          ctl.clearValidators();
        }
      }
      ctl.updateValueAndValidity({ onlySelf: true });
    })
  }

  private disabledFields(fields: string[], value: boolean) {
    fields.forEach(field => {
      const ctl = this.form.get(field);
      value ? ctl.enable() : ctl.disable();
    });
  }

  private getModeOfTransport(selectedModeOfTransport: ModeOfTransport) {
    switch (selectedModeOfTransport) {
      case ModeOfTransport.bicycle: return 'bicycling';
      case ModeOfTransport.walking: return 'walking';
      default: return 'driving';
    }
  }

  protected requestTitleChipSelected(drtChip: string) {
    const titleCtl = this.form.get('title');
    let formTitleValue = titleCtl.value || '';
    if (formTitleValue) {
      titleCtl.setValue(formTitleValue += ' ' + drtChip);
    } else { titleCtl.setValue(formTitleValue +=  drtChip); }
  }

  public getServices(wId: string): Service[] {
    if (this.role.roleType === RoleTypes.coordinator) {
      this.form.get('serviceId').setValue(this.services.find(s => s.id === this.role.serviceId)?.id);
    }
    return this.services.filter(s => s.workingAreaId === wId);
  }

  public isWorkingAreaSelectDisabled(): boolean {
    return this.role.roleType === RoleTypes.coordinator || this.role.roleType === RoleTypes.professional;
  }

  public isServiceSelectDisabled() {
    return this.role.roleType === RoleTypes.coordinator;
  }

  protected openAddressModal(type: 'fromAddress' | 'toAddress') {
    let address: Address;
    if (type == 'fromAddress') address = this.addressMap.fromAddress;
    else address = this.addressMap.toAddress;

    this.userPromptsService.showDialogue(AddressModalComponent, { address, type }, async (data: { address: Address, area: IArea }) => {
      if (data) {
        if (type == 'fromAddress') {
          this.addressMap.fromAddress = data.address;
          this.addressMap.fromArea = data.area;
          this.form.get('goTogetherDetails.fromAddress').setValue(this.contactAddressStr(this.addressMap.fromAddress));
        } else {
          this.addressMap.toAddress = data.address;
          this.addressMap.toArea = data.area;
          this.form.get('goTogetherDetails.toAddress').setValue(this.contactAddressStr(this.addressMap.toAddress));
        }
        this.getDistanceMatrix();
      }
    });
  }

  private async getDistanceMatrix() {
    const modal = this.form.value;
    const fromAddress: Address = this.addressMap.fromAddress;
    const toAddress: Address = this.addressMap.toAddress;
    const modeOfTransport: ModeOfTransport = modal.goTogetherDetails.modeOfTransport;
    if (fromAddress && toAddress && modeOfTransport) {
      this.loading = true;
      const payload = {
        origins: [`${fromAddress.geo.latitude},${fromAddress.geo.longitude}`],
        destinations: [`${toAddress.geo.latitude},${toAddress.geo.longitude}`],
        mode: this.getModeOfTransport(modeOfTransport)
      }
      const res: any = await this.requestService.getAddressDistance(payload);
      const data = res.rows[0].elements[0];
      this.distanceMatrix.distance = data.distance;
      this.distanceMatrix.duration = data.duration;
      this.loading = false
    }
  }

  public closeRequestModal(): void {
    this.dialogRef.close();
  }

  public async save() {
    const model = this.form.value;
    // const date = new Date();
    const service = this.currentService;
    const goTogetherDetails: GoTogetherDetails = Object.assign(model.goTogetherDetails, this.distanceMatrix);
    goTogetherDetails.fromAddress = this.addressMap.fromAddress || this.customer?.area;
    goTogetherDetails.fromArea = this.addressMap.fromArea || this.customer.area;
    goTogetherDetails.toAddress = this.addressMap.toAddress;
    goTogetherDetails.toArea = this.addressMap.toArea;
    const goTogetherDate = addHours(startOfDay(new Date((goTogetherDetails as any).scheduledForDate)), goTogetherDetails.pickupTime);

    const request: Request = {
      management: {
        workareaId: model.workareaId,
        serviceId: service.id,
        executor: null,
        executorId: null,
        coordinatorId: null,
        team: {},
        customerId: this.customer.id,
        customer: {
          firstname: this.customer.firstname,
          lastname: this.customer.lastname,
          phone: this.customer.phone || this.customer.phone2 || '',
          picture: this.customer.picture || '',
          userId: this.customer.id,
        }
      },
      area: this.customer.area || null,
      title: model.title,
      description: model.description,
      note: '',
      endDate: this.currentService?.settings?.goTogether?.activated ? format(goTogetherDate, 'YYYY-MM-DD') : null,
      budget: null,
      referenceNumber: null,
      startDate: this.currentService?.settings?.goTogether?.activated ? format(goTogetherDate, 'YYYY-MM-DD') : null,
      status: service.settings.defaultStatusCreated,
      referredBy: model.referredBy,
      referredByNote: model.referredByNote,
      searchIndex: (new Request()).searchIndex,
      closeRemark: '',
      redirectedTo: null,
      followupSteps: null,
      requestKind: model.requestKind,
      activeIn: { months: [], years: [] },
      isClosed: false,
      requestStoppedReason: null,
      published: false,
      publishScope: null,
      checklistItems: service.settings?.defaultChecklist?.items || [],
      activity: new RequestActivity(),
      appointmentId: '',
      goTogetherDetails: new GoTogetherDetails(goTogetherDetails),
      scheduledFor: goTogetherDetails.appointmentTime ? goTogetherDate : null,
      sequenceId: '',
      requestDurationType: model.requestDurationType || this.currentService.settings.catalog.defaultRequestDurationType || RequestDurationTypes.ongoing
    };

    this.loading = true;
    request.id = await this.requestService.createRequest(JSON.parse(JSON.stringify(request)));

    const userInfo = {
      userId: request.management.customerId,
      firstName: request.management.customer.firstname,
      lastName: request.management.customer.lastname
    };

    this.usageService.addActivity(
      UsageActionTypes.create_request,
      userInfo,
      request
    );

    this.router.navigate([`/${this.orgService.currentOrgId}/dashboard/requests/detail`, request.id], { relativeTo: this.route });
    this.userService.saveUserRole(this.customer, 'customer', undefined, 'add', model.workareaId, service, true)
      .then(res => {
        console.log(res)// this.userPromptsService.showToast(this.labels[res]);
      }).catch((error) => {
        console.log(error);
        this.userPromptsService.showToast(error.message);
      });
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
