<app-modal [alignFooterStart]="true">
  <div class="modal_header" #modal_header>
    <div class="modal_title">{{user?.fullname}}</div>
    <div class="modal_subtitle">{{labels.availability}}</div>
  </div>

  <div class="modal_body" #modal_body>
    <div class="p-3" fxLayout="column" fxLayoutGap="15px" *ngIf="user.availability as av">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            {{labels.availability}}
          </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <div class="mt-3" fxLayout="row" fxLayout.xs="column">
            <div fxLayout="row" fxLayoutGap="10px">
              <div fxFlex="120px"></div>
              <mat-form-field appearance="outline" class="full-width" style="width: 150px;">
                <mat-label>{{labels.availability_status}}</mat-label>
                <mat-select [(ngModel)]="av.availability">
                  <mat-option *ngFor="let m of availabilityStatus" [value]="m.id">
                    {{ labels[m.status] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            @if (av.availability == UserAvailabilityTypes.available) {
            <div fxLayout="row" fxLayoutGap="10px" fxFlex="stretch">
              <div fxFlex="120px"></div>
              <mat-form-field appearance="outline" class="full-width" fxFlex="stretch">
                <mat-label>{{labels.week_days}}</mat-label>
                <mat-select multiple [(ngModel)]="selectedWeekdays" (ngModelChange)="weekdaySelected()">
                  <mat-option *ngFor="let weekday of weekdays" [value]="weekday">
                    {{ labels[weekday.name] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            }
          </div>

          <div fxLayout="row" fxLayoutGap="10px">
            <div fxFlex="120px"></div>
            @if (av.availability == UserAvailabilityTypes.available) {
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>{{labels.availability_comment}}</mat-label>
              <input matInput [(ngModel)]="av.comment">
            </mat-form-field>
            } @else {
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>{{labels.not_available_comment}}</mat-label>
              <input matInput [(ngModel)]="av.commentNotAvailable">
            </mat-form-field>
            }
          </div>

          @if (av.availability == UserAvailabilityTypes.available) {
          <div *ngFor="let week of selectedWeekdays" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
            <div class="" fxLayout="row" fxLayoutGap="7px" fxFlex="115px" fxLayoutAlign="start center">
              <div fxFlex="stretch"> {{week.name}} </div>
            </div>

            <div *ngIf="av.days[week.prop].available" fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px"
              fxFlex="stretch">
              <mat-form-field appearance="outline" fxFlex="stretch">
                <mat-label>{{labels.availability}}</mat-label>
                <mat-select multiple [(ngModel)]="availabilityDays[week.prop]">
                  <mat-option *ngFor="let m of availabilitySessions" [value]="m">
                    {{ labels[m] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          }
        </mat-card-content>
      </mat-card>


      <mat-card>
        <mat-card-header>
          <mat-card-title>
            {{labels.planned_leave}}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="mt-3">
            @if (tableCfg?.data?.length) {
            <data-table [configOptions]="tableCfg" (onSelectRow)="handleRowSelect($event)"
              (onSelectRowAction)="handleRowAction($event)">
            </data-table>
            }
          </div>
        </mat-card-content>
        <mat-card-actions align="end">
          <button mat-stroked-button color="primary" (click)="addPlannedLeave()">{{labels.add}}</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

  <div class="modal_footer" #modal_footer fxLayoutGap="10px">
    <div fxFlex="stretch" fxLayoutAlign="start center">
    </div>

    <div>
      <button mat-button color="primary" (click)="saveAvailability()">{{labels.btn_save}}</button>
    </div>
  </div>
</app-modal>