import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelService } from '@core/services';
import { Address, IArea } from '@models/model';
import { cloneDeep } from 'lodash';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss']
})
export class AddressModalComponent implements OnInit {

  public labels = this.labelService.defaultProvider();
  protected address: Address;
  protected area: IArea;
  protected canSave = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { address: Address },
    private labelService: LabelService,
    private dialogRef: MatDialogRef<AddressModalComponent>,
  ) { }

  async ngOnInit(): Promise<void> {
    this.dialogRef.disableClose = true;
    this.address = cloneDeep(this.data.address);
    this.labels = await this.labelService.getLabels$('app-address-modal').pipe(take(1)).toPromise();
  }

  protected updateAddress(data: { address: Address, area: IArea }) {
    this.canSave = true;
    this.address = data.address;
    this.area = data.area;
  }

  protected save() {
    this.dialogRef.close({ address: JSON.parse(JSON.stringify(this.address)), area: this.area });
  }

}
