<div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px" class="inline-list-main-container">

    <div style="width: 70%;">
        <mat-card class="no-header inline-list-container">
            <div class="inline-list-table-container">
                @if (loading) {<mat-progress-bar mode="indeterminate"></mat-progress-bar>}
                <mat-card-header>
                    <div fxLayout="row" fxFlex="stretch">
                        <mat-form-field appearance="outline" style="width: 150px; padding-right: 10px;">
                            <mat-label>{{labels.search}}</mat-label>
                            <input matInput [(ngModel)]="serviceRegFilter.textFilter"
                                (ngModelChange)="checkTextFilterValid()" (keydown)="handleFilterSubmit()">
                        </mat-form-field>
                        <div style="padding-left: 5px; padding-right: 10px; margin-top: 4px;">
                            <button mat-mini-fab
                                [disabled]="!anyNonSearchTextFiltersSet() && !serviceRegFilter.textFilter"
                                (click)="clearFilters()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                        <div fxFlex></div> <!-- This will push the button to the right -->
                        <!-- <button style="margin-top: 4px;" mat-raised-button color="primary"
                            (click)="openAddUserDialog()">{{labels.add_request}}</button> -->
                    </div>
                </mat-card-header>
                <mat-selection-list>
                    <mat-list-item *ngIf="tableCfg?.length === 0">
                        <mat-list-item>
                            <mat-icon matListItemIcon>information</mat-icon>
                            <div matListItemTitle>{{labels.no_service_registration_found }}
                            </div>
                            <div matListItemLine>{{labels.empty_service_registration_desc}}</div>
                        </mat-list-item>
                    </mat-list-item>
                    <div class="grid-container">
                        <div class="grid-item" *ngFor="let data of tableCfg"
                            style="display: grid; grid-template-columns: 45% 40% 10%; gap: 0px;">
                            <div>
                                <mat-divider></mat-divider>
                                <mat-list-item (click)="handleRowSelect(data, $event)" lines="3">
                                    <button mat-icon-button matListItemIcon (click)="rowIconClicked(data, $event)">
                                        <mat-icon color="primary">
                                            <span class="material-symbols-rounded">
                                                message_text_outline
                                            </span>
                                        </mat-icon>
                                    </button>

                                    <span matListItemTitle>{{data.title}}</span>
                                    <span>{{data.customer}}</span>
                                </mat-list-item>
                            </div>
                            <div>
                                <mat-divider></mat-divider>
                                <mat-list-item (click)="handleRowSelect(data, $event)" lines="3">
                                    <div matListItemTitle>
                                        @if (userRole.roleType != RoleTypes.coordinator) {
                                        {{data.service}}
                                        }
                                    </div>
                                    <div matListItemLine>
                                        @if (userRole.roleType != RoleTypes.networkPartner) {
                                        {{data.networkPartner}}
                                        }
                                    </div>
                                </mat-list-item>
                            </div>
                            <!-- <div>
                                <mat-divider></mat-divider>
                                <div>
                                    <div fxLayout="column" fxLayoutAlign="center" style="padding-top: 5px;">
                                        <div fxLayout="row" fxLayoutAlign="center">
                                            <button mat-icon-button (click)="openUsersInfoDialog(data.originalRequest)">
                                                <mat-icon>
                                                    <span class="material-symbols-outlined">contact_page</span>
                                                </mat-icon>
                                            </button>
                                            <button mat-icon-button (click)="openChecklistDialog(data.originalRequest)">
                                                <mat-icon>checklist</mat-icon></button>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </mat-selection-list>
            </div>
            <mat-paginator class="inline-list-paginator" (page)="onPaginationChange($event)"
                [length]="paginationOptions.totalSize" [pageSize]="paginationOptions.serviceRegistrationsPerPage"
                [pageIndex]="paginationOptions.pageIndex" [pageSizeOptions]="[5, 10, 30, 50]" aria-label="Select page">
            </mat-paginator>
        </mat-card>
    </div>
    <div style="width: 30%;" fxLayout="column">
        <mat-card>
            <div fxLayout="row">
                <mat-form-field appearance="outline" [ngClass]="[shouldHideServiceFilter ? '' : 'full-width']"
                    style="padding-left: 10px; padding-right: 10px;">
                    <mat-label>{{labels.statuses}}</mat-label>
                    <mat-select multiple [(ngModel)]="serviceRegFilter.statusFilter"
                        (ngModelChange)="handleFilterSubmit()">
                        <mat-option [ngClass]="['status-'+status]" *ngFor="let status of serviceRegStatuses"
                            [value]="status">
                            {{labels[ServiceRegStatusTypes[status]]}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="row">
                <mat-form-field appearance="outline" *ngIf="!shouldHideServiceFilter" class="full-width"
                    style="padding-left: 10px; padding-right: 10px;">
                    <mat-label>{{labels.service}}</mat-label>
                    <mat-select [(ngModel)]="serviceRegFilter.serviceFilter" (ngModelChange)="handleFilterSubmit()">
                        <mat-option [value]="null">{{labels.show_all}}</mat-option>
                        <mat-option *ngFor="let service of services" [value]="service.id">
                            {{service.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-card>
    </div>
</div>