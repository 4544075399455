import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivityService, FileService, LabelService, OrganizationService, RequestService, RoleService, UsageService, UserPromptsService, UserService, UtilitiesService } from '@core/services';
import { AppointmentService } from '@core/services/appointment/appointment.service';
import { IObjectMap } from '@models/interface';
import { ICustomFormModelData, IMailinglistItem, Management, Organization, SocialRelationKindTypes, UsageActionTypes, User } from '@models/model';
import { AppointmentModel, AppointmentTypes } from '@models/model/appointment.model';
import { ImageHandlerConfig, ImageHandlerModalComponent, ImageHandlerOutput } from '@shared/entry-components';
import { EditAppointmentModalComponent } from '@shared/entry-components/edit-appointment-modal/edit-appointment-modal.component';
import { ImagePreviewComponent } from '@shared/entry-components/image-preview/image-preview.component';
import { EditUserComponent } from '@users/components/edit-user/edit-user.component';
import { UserAvailabilityModalComponent } from '@users/components/user-availability-modal/user-availability-modal.component';
import { differenceInYears, format, isValid } from 'date-fns';
import { cloneDeep } from 'lodash';
import { NgSub } from 'ng-sub';
import { takeUntil, take } from 'rxjs/operators';
import { ToastComponent } from '../toast/toast.component';
import { UsageDisplayModelComponent } from '../usage-display-model/usage-display-model.component';
import { MailinglistService } from '@core/services/mailinglist/mailinglist.service';
import { ContactPersonDetailsDialogComponent } from '../contact-person-details-dialog/contact-person-details-dialog.component';
import { isValid as isIbanValid } from "iban";
import { ActivatedRoute, Router } from '@angular/router';
import { UserRequestsComponent } from '../user-requests/user-requests.component';

@Component({
  selector: 'app-person-tab',
  templateUrl: './person-tab.component.html',
  styleUrls: ['./person-tab.component.scss']
})
export class PersonTabComponent implements OnInit, OnChanges, OnDestroy {
  @Input() customer: User;
  @Input() fromPage: string;

  public labels = this.labelService.defaultProvider();
  public personInfoForm: FormGroup;
  public savingPersonalForm = false;
  public extraInfoForm: FormGroup;
  public savingExtraForm = false;
  public userCustomForms: IObjectMap<ICustomFormModelData> = {};
  public appointments: AppointmentModel[] = [];
  public updatedBy: User;
  public createdBy: User;
  public management: Management;
  public currentAreaId: string;
  public currentServiceId: string;
  private sub = new NgSub();
  public isGetMailingList: boolean = false;
  public mailinglists: IMailinglistItem[];
  public loggedInUser: User;
  protected isMobile: boolean;
  public socialKind = SocialRelationKindTypes;
  protected afasForm: FormGroup;
  protected org: Organization;
  protected connectingAfas = false;
  protected isUserConnectedToAfas = false;
  protected extraInfoShowMore: boolean = false;
  protected personalInfoShowMore = false;
  protected isDeletingUser = false;

  constructor(
    private labelService: LabelService,
    private utilitiesService: UtilitiesService,
    private fb: FormBuilder,
    private userPromptsService: UserPromptsService,
    private userService: UserService,
    private appointmentService: AppointmentService,
    private fileService: FileService,
    private organisationService: OrganizationService,
    // private cloudFunctionService: CloudFunctionService,
    private usageService: UsageService,
    private mailinglistService: MailinglistService,
    private roleService: RoleService,
    private requestService: RequestService,
    private router: Router,
    private route: ActivatedRoute,
    private activityService: ActivityService
  ) { }

  async ngOnInit() {
    this.sub.add(
      this.userPromptsService.activeMediaQuery$.subscribe(query => {
        this.isMobile = query == 'xs';
      })
    );
    this.sub.add(
      this.organisationService.getCurrentOrganization().subscribe(org => {
        this.org = org;
      })
    )
    this.labels = (await this.labelService.getLabels('app-customer-tab')).data;

    this.setupPersonalInfoForm();
    this.setupExtraInfoForm();
    this.userService.getCurrentUser().pipe(take(1)).toPromise()
      .then(user => this.loggedInUser = user);
    this.appointmentService.getAppointmentsForUser(this.customer.id)
      .pipe(takeUntil(this.sub))
      .subscribe({
        next: appts => {
          this.appointments = appts;
        },
        error: e => console.log(e),
      });

    if (this.customer.log.createdBy !== this.updatedBy?.id && this.customer.log.createdBy) {
      this.userService.getUserById(this.customer.log.createdBy).pipe(take(1)).subscribe(user => this.createdBy = user);
    }
    // Hence showing usage actions user note is could be removed
    if (this.customer.usernote) {
      this.userPromptsService.showToast(this.customer.usernote);
    }

    if (this.customer.log.modifiedBy !== this.createdBy?.id && this.customer.log.modifiedBy) {
      this.userService.getUserById(this.customer.log.modifiedBy).pipe(take(1)).subscribe(user => this.updatedBy = user);
    }
    this.getLastUsage();
    this.getMailingList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.utilitiesService.isNewChange(changes.customer)) {
      this.setupAfasForm();
    }
  }

  public openEditUser(): void {
    this.userPromptsService.showDialogue(EditUserComponent, { user: this.customer }, undefined, false);
  }

  public toDate(d: any, skipYear?: boolean) {
    const date = new Date(d);

    if (isValid(date)) {
      return skipYear ? this.utilitiesService.getLocaleDateString(date) : this.utilitiesService.getLocaleDateWithYear(date)
    } else {
      return '';
    }
  }

  public updatePhoneField(form: FormGroup, field: string, value: { phone: string; valid: boolean; }) {
    const ctrl = form.get(field);
    ctrl.setValue(value.phone);
    ctrl.setErrors(value.valid ? null : { invalid: true });

    form.markAsDirty();
  }

  public updateProfilePicture() {
    const orgId = this.organisationService.currentOrgId;

    const options: ImageHandlerConfig = {
      maintainAspectRatio: true,
      uploadFolder: `organizations/${orgId}/users/${this.customer.id}`,
      resizeWidth: 200
    };

    if (this.customer.picture) {
      options.defaultImages = [this.customer.picture];
    }

    this.userPromptsService.showDialogue(ImageHandlerModalComponent, options, async (res: ImageHandlerOutput) => {
      if (res) {
        if (res.errorMsg) {
          this.userPromptsService.showToast(res.errorMsg);
        } else {
          // delete previous image from storage
          const previousImage = this.customer.picture;
          if (previousImage) {
            this.fileService.deleteFileByUrl(previousImage);
          }

          this.customer.picture = res.downloadUrl;
          await this.userService.updateUser(this.customer);

          this.userPromptsService.showToast(this.labels.profile_picture_udpated);
        }
      }
    });
  }

  public openImageModal(pix: string): void {
    this.userPromptsService.showDialogue(ImagePreviewComponent, { image: pix });
  }

  public editAppointment(appointment: AppointmentModel): void {
    this.userPromptsService.showDialogue(EditAppointmentModalComponent, {
      appointment,
      customerId: this.customer.id,
    }, null, true);
  }

  public deleteAppointment(appointment: AppointmentModel): void {
    if (appointment.docRef) {
      this.userPromptsService.showToast('Cannot delete due to a connected reference');
    } else {
      this.userPromptsService.confirmPromise(this.labels.confirm, this.labels.delete_appointment_warning).then(sure => {
        if (sure) {
          this.appointmentService.deleteAppointment(appointment.id);
        }
      });
    }
  }

  public newAppointment(): void {
    const appointment = new AppointmentModel(AppointmentTypes.activity);
    this.editAppointment(appointment);
  }

  public canOpenGoogleMaps(): boolean {
    const geo = this.customer.address?.geo;

    return !!(geo?.latitude && geo?.longitude);
  }

  protected openGoogleMaps() {
    const url = `https://www.google.com/maps/search/?api=1&query=${this.trimSpaces(this.customer.address.street)}+${this.customer.address.number},+${this.customer.address.postalcode}+${this.trimSpaces(this.customer.address.city)}`;
    window.open(url, '_black');
  }

  private trimSpaces(str: string) {
    let trimmedStr = '';
    let strSplit = str.split(' ');
    strSplit.forEach((str) => trimmedStr += str);
    return trimmedStr;
  }

  public age(): number {
    const bd = new Date(this.customer.birthday);

    return isValid(bd) ? differenceInYears(new Date(), bd) : 0;
  }


  public setupExtraInfoForm(): void {
    const c = this.customer;
    this.extraInfoForm = this.fb.group({
      personalNumber: [c.personalNumber, [this.checkBSN()]],
      code: [c.code],
      usernote: [c.usernote],
      mailinglistSubscriptions: [c?.mailinglistSubscriptions],
      iban: [c.iban, [this.verifyIban()]]
    });
  }

  private checkBSN(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
      this.utilitiesService.elfProefValidation(control.value, 'bsn') == true
        ? null : { wrongNumber: this.labels.wrongNumber };
  }

  protected verifyIban() {
    return (control: AbstractControl): { [key: string]: any } | null =>
      !control.value ? null : isIbanValid(control.value) ? null : { wrongNumber: this.labels.wrongNumber };
  }

  public async saveExtraInfoForm() {
    const model = this.extraInfoForm.value;
    const c = cloneDeep(this.customer);
    c.personalNumber = model.personalNumber || '';
    c.code = model.code || '';
    c.usernote = model.usernote || '';
    c.mailinglistSubscriptions = model.mailinglistSubscriptions;
    c.iban = model.iban;
    this.savingExtraForm = true;
    await this.userService.updateUser(c);
    this.customer = c;
    this.savingExtraForm = false;

    this.setupExtraInfoForm();
  }



  public setupPersonalInfoForm(): void {
    const c = this.customer;

    this.personInfoForm = this.fb.group({
      phone: [c.phone],
      phone2: [c.phone2],
      phoneRemark: [c.phoneRemark],
      phone2Remark: [c.phone2Remark],
      email: [c.email, [Validators.email]],
      email2: [c.email2, [Validators.email]],
      emailRemark: [c.emailRemark],
      email2Remark: [c.email2Remark],
    });
  }

  public async savePersonlInfoForm() {
    const model = this.personInfoForm.value;

    const c = cloneDeep(this.customer);

    c.phone = model.phone || '';
    c.phoneRemark = model.phoneRemark || '';

    c.phone2 = model.phone2 || '';
    c.phone2Remark = model.phone2Remark || '';

    c.email = model.email || '';
    c.emailRemark = model.emailRemark || '';

    c.email2 = model.email2 || '';
    c.email2Remark = model.email2Remark || '';

    this.savingPersonalForm = true;
    await this.userService.updateUser(c);
    this.customer = c;
    this.savingPersonalForm = false;

    this.setupPersonalInfoForm();
  }

  public openAvailabilityDialog(): void {
    this.userPromptsService.showDialogue(UserAvailabilityModalComponent, {
      user: cloneDeep(this.customer),
    }, null);
  }

  public copyToClipboard(text: string): void {
    this.utilitiesService.copyTextToClipboard(text);
    this.userPromptsService.showToast(this.labels.copied_to_clipboard_toast);
  }

  private getLastUsage() {
    setTimeout(() => {
      let details: any = {};
      this.usageService.getUsageCollectionGroup('usage', () => {
        return [['actionTo.userId', '==', this.customer.id]];
      }, { orderBy: [{ field: 'timestamp', val: 'desc' }], limit: 2 })
        .then(usageDoc => {
          const usage = usageDoc[1];

          if (usage && usage.actionBy.userId !== this.userService.getCurrentUserId()) {
            details.message = `${this.labels[UsageActionTypes[usage.action]]} - ${usage.actionBy.firstName} ${usage.actionBy.lastName} ${format(usage.timestamp, 'DD-MM-YYYY')}`
            details.icon = 'history';
            details.userId = this.customer.id;
            this.userPromptsService.showToastFromTemplate(ToastComponent, JSON.stringify(details), 4000);
          }
        })
    }, 5000);
  }

  public openUsageDocModal() {
    this.userPromptsService.showDialogue(UsageDisplayModelComponent, { userId: this.customer.id })
  }

  private getMailingList() {
    this.mailinglistService.getAciveMailinglists().subscribe({
      next: m => {
        this.mailinglists = m;
      },
      error: e => this.utilitiesService.errHandler(e)
    })
  }

  protected openContactPersonDetailDialog(type: 'userContactPerson' | 'userContactPerson2') {
    this.userPromptsService.showDialogue(ContactPersonDetailsDialogComponent, { contactPerson: this.customer[type] }, null, false, { width: '350px' });
  }

  private setupAfasForm() {
    this.afasForm = this.fb.group({
      number: [this.customer?.afasDetails?.KnPerson?.BcCo],
      id: [this.customer?.afasDetails?.KnPerson?.BcId],
      isExecutor: [this.customer?.afasDetails?.isAfasExecutor]
    })
    this.afasForm.get('id').disable();
    this.afasForm.get('number').disable();
    if (!this.customer?.afasDetails?.connected) this.afasForm.get('isExecutor').disable();
  }

  protected toggleAfasConnect() {
    if (!this.customer.address?.street || !this.customer.gender || !this.customer.birthday || !this.customer.email || !this.customer.iban) {
      return this.userPromptsService.showToast(this.labels.api_afas_address_required)
    }
    this.connectingAfas = true;
    if (this.customer?.afasDetails?.connected) {
      this.customer.afasDetails.connected = false;
      return this.userService.updateUser(this.customer).then(() => {
        this.connectingAfas = false;
        this.userPromptsService.showToast(this.labels.disconnected);
      })
    }
    this.userService.afasConnect(this.org.id, this.customer)
      .then((user) => {
        this.customer = user;
        this.userService.updateUser(this.customer).then(() => {
          this.connectingAfas = false;
          this.userPromptsService.showToast(this.labels.connected);
        })
      }).catch(error => {
        this.connectingAfas = false;
        console.log(error);
        this.userPromptsService.showToast(this.labels._translate('connect_error', { message: error.message }));
      });
  }

  protected updateAfasUser() {
    this.connectingAfas = true;
    this.customer.afasDetails.isAfasExecutor = this.afasForm.get('isExecutor').value;
    this.userService.updateAfasUserRoleAsExecutor(this.org.id, this.customer, 'set user as executor').then(_res => {
      return this.userService.updateUser(this.customer).then(() => {
        this.connectingAfas = false;
      });
    })
  }

  protected getAfasUserByEmail() {
    if (this.customer.afasDetails?.connected && this.customer.email) {
      this.userService.getAfasUserWithEmail(this.org.id, this.customer.email, 'get user by email').then(res => {
        this.isUserConnectedToAfas = !!res.rows.length;
      })
    }
  }

  public openUserRequests(): void {
    this.userPromptsService.showDialogue(UserRequestsComponent, { user: this.customer }, null, true);
  }

  protected async openDeleteUserDialog() {
    const user = this.customer;
    let confirmText = this.labels.confirm_delete_user_warning;

    // check user roles
    this.isDeletingUser = true;
    const  roles = await this.roleService.getRoles(user.id, true).pipe(take(1)).toPromise();
    const filteredRoles = roles.filter(role => role.roleType !== 10);

    if (filteredRoles.length > 0) {
      this.isDeletingUser = false;
      this.userPromptsService.showAlertDialogue(
        this.labels.action_denied,
        this.labels._translate('user_has_roles_warning_text', { roles })
      );

      return;
    }

    // get requests where user is customer
    const rqs = await this.requestService.getAllCustomerRequests(user.id).pipe(take(1)).toPromise();
    if (rqs.length > 0) {
      confirmText = this.labels._translate('confirm_delete_user_and_requests', { count: rqs.length });
    }

    this.userPromptsService.confirmPromise(
      this.labels.confirm_action,
      confirmText
    ).then(async (sure) => {
      if (sure) {
        const promise: Promise<any>[] = [this.markSubCollectionForDeletion()];

        if (rqs.length > 0) {
          promise.push(Promise.all(rqs.map(r => this.requestService.deleteRequest(r))))
        }
        await Promise.all(promise).catch(console.log);

        this.userService.deleteUser(user.id)
          .then(res => {
            console.log(res);
            this.router.navigate(['../../user-list'], { relativeTo: this.route });
            this.userPromptsService.showToast(this.labels.user_delete_successful);
          })
          .catch(e => console.log(e));
      }

      this.isDeletingUser = false;
    });
  }
  
  private markSubCollectionForDeletion() {
    return this.activityService.getAllUserActivities(this.customer.id).then(activities => {
      const updatedActivities = activities.map(activity => {
        activity.deleteReporting = true;
        return activity;
      })
      return this.activityService.updateActivities(this.customer.id, updatedActivities);
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
