import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FirestoreService } from './firestore.service';
import { LocalStorageService } from './local-storage.service';
import { WorkingArea } from 'app/shared/model';
import { QueryFn } from '@angular/fire/compat/firestore';

@Injectable({
    providedIn: 'root',
})
export class WorkingAreaService {
    private orgId: string;

    constructor(
        private localStorageService: LocalStorageService,
        private afsDB: FirestoreService
    ) {
        this.localStorageService.getItem('user_organization').subscribe((res) => {
            this.orgId = res;
        });
    }

    public getWorkingAreasForProfessional(professionalId: string): Promise<WorkingArea[]> {
        return this.afsDB.colWithIdsNoCache(`/organizations/${this.orgId}/workingAreas`, (ref) => {
            return ref.where('active', '==', true)
                .where(`professionals.${professionalId}`, '==', true);
        });
    }

    public getWorkingAreasForProfessional$(professionalId: string): Observable<WorkingArea[]> {
        return this.afsDB.colWithIds$(`/organizations/${this.orgId}/workingAreas`, (ref) => {
            return ref.where('active', '==', true)
                .where(`professionals.${professionalId}`, '==', true);
        });
    }

    public getWorkingAreas(queryFn?: any): Observable<WorkingArea[]> {
        return this.afsDB.colWithIds$(`/organizations/${this.orgId}/workingAreas`, queryFn);
    }

    public getWorkingAreasNoCache(queryFn?: QueryFn): Promise<WorkingArea[]> {
        return this.afsDB.colWithIdsNoCache(`/organizations/${this.orgId}/workingAreas`, queryFn);
    }

    public getWorkingAreasForCustomForm(formId: string): Observable<WorkingArea[]> {
        return this.getWorkingAreas(ref => {
            return ref.where(`connectedCustomForms.${formId}`, '==', true);
        });
    }

    public getWorkingAreasForCatalog(): Observable<WorkingArea[]> {
        return this.getWorkingAreas(ref => {
            return ref.where('settings.serviceCatalog', '==', true);
        });
    }

    public updateLocalStorageWorkingArea(workingArea: WorkingArea) {
        // update localStorage
        const userRole = JSON.parse(this.localStorageService.getItemSync('user_role'));
        userRole.workingArea = workingArea;
        this.localStorageService.setItem('user_role', JSON.stringify(userRole));
    }

    getWorkingAreaById(areaId: string): Observable<WorkingArea> {
        return this.afsDB.docWithId$(`/organizations/${this.orgId}/workingAreas/${areaId}`);
    }

    getWorkareasFromIds(ids: string[]): Observable<WorkingArea[]> {
        return this.afsDB.docsFromId$(`/organizations/${this.orgId}/workingAreas`, ids);
    }

    getWorkareasFromIdsNoCache(ids: string[]): Promise<WorkingArea[]> {
        return this.afsDB.ColWithIdsNoCacheNew(`/organizations/${this.orgId}/workingAreas`, () => [['id', 'in', ids]]);
    }

    updateWorkingArea(workingArea) {
        return this.afsDB.update(`/organizations/${this.orgId}/workingAreas/${workingArea.id}`, workingArea);
    }

    createWorkingArea(workingArea) {
        return this.afsDB.add(`/organizations/${this.orgId}/workingAreas`, workingArea);
    }

    deleteWorkingArea(areaId: string) {
        return this.afsDB.doc(`/organizations/${this.orgId}/workingAreas/${areaId}`).delete();
    }
}
