<app-modal>
  <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
    <span class="modal_title">{{labels.import_users}}</span>
  </div>

  <div class="modal_body" #modal_body>

    <div *ngIf="form">
      <mat-vertical-stepper #stepper [linear]="isLinear">
        <mat-step>
          <ng-template matStepLabel>{{labels.selecting_file}}</ng-template>
          <div>
            <button mat-raised-button color="primary" value="file" (click)="onFileSelect()">
              {{labels.select_file}}
            </button>
            <div>
              <form [formGroup]="form" fxLayout="column" role="form">
                <mat-form-field appearance="outline" class="full-width">
                  <input matInput class="form-control" formControlName="filePath">
                  <input #userImport type="file" (change)="onFileSelected($event, stepper)" style="visibility:hidden">
                </mat-form-field>
              </form>
            </div>
          </div>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>{{labels.map_fields}}</ng-template>
          <div *ngIf="dbExcelMappings?.length">
            <mat-card-content>
              <div class="datagrid">
                <table>
                  <thead>
                    <th>
                      <mat-checkbox color="primary" [disabled]="true" [checked]="isAllSelected(dbExcelMappings)"
                        [indeterminate]="isPartiallySelected(dbExcelMappings)">
                      </mat-checkbox>
                    </th>
                    <th>{{labels.fields}}</th>
                    <th>
                      {{labels.excel_fields}} - ({{excelFields.length}})
                    </th>
                  </thead>

                  <tbody>
                    <tr *ngFor="let field of dbExcelMappings">
                      <td>
                        <mat-checkbox color="primary" (change)="field.selected = !field.selected" [disabled]="true"
                          [checked]="field.selected">
                        </mat-checkbox>
                      </td>
                      <td> {{field.dbField}}</td>
                      <td>
                        <mat-select placeholder="Excel Fields" (selectionChange)="updateMapping(field, $event.value)"
                          [ngModel]="field.excelField || '-'">
                          <mat-option *ngFor="let field of getExcelFields(field.dbField)" [value]="field">{{ field }}
                          </mat-option>
                        </mat-select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </mat-card-content>

            <div>
              <button mat-button matStepperPrevious>
                {{labels.back}}
              </button>
              <button mat-button [disabled]="!form.valid" (click)="mapData(stepper)">
                {{labels.next}}
              </button>
            </div>
          </div>
        </mat-step>

        <!-- <mat-step [stepControl]="thirdFormGroup"> -->
        <mat-step>
          <ng-template matStepLabel>
            {{labels.chose_users_to_import}}
          </ng-template>
          <div *ngIf="usersToImport.length > 0">
            <data-table [configOptions]="getTableConfigUsersToImport()" (onSubmit)="uploadUsers($event)">
            </data-table>
          </div>
          <div *ngIf="usersNotToImport?.length">
            <data-table [configOptions]="getTableConfigUsersNotToImport()" (onSubmit)="updateUsers($event)">
            </data-table>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </div>

  <div class="modal_footer" #modal_footer fxLayoutGap="10px">
    @if (inProcess) {
    <small>{{labels.processing}} {{counter}} {{labels.of}} {{totalUsers}}</small>
    }
  </div>
</app-modal>