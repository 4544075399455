<div fxLayout="column" fxLayoutGap="20px" style="padding-bottom: 30px;">
    <div fxLayoutGap="20px" fxLayout="row" fxLayout.xs="column">
        <div [style]="{width: isMobile ? '100%' : '49%'}">
            <mat-card fxLayout="column" style="min-height: 160px;">
                <mat-card-header>
                    <mat-card-title>
                        <div class="card-title">{{labels.documents}}</div>
                    </mat-card-title>
                </mat-card-header>
                <mat-progress-bar *ngIf="docLoading" mode="determinate" [value]="progress"></mat-progress-bar>
                <div class="content" *ngIf="docTableConfig?.data?.length">
                    <data-table [configOptions]="docTableConfig" (onSelectRow)="openDoc($event)"
                        (onSelectRowAction)="handleDocRowAction($event)">
                    </data-table>
                </div>
                <div fxFlex="stretch"></div>

                <mat-card-actions [fxLayoutAlign]="docTableConfig?.data?.length >= 3 ? 'space-between' : 'end'">
                    <button mat-button color="primary" *ngIf="docTableConfig?.data?.length >= 3"
                        (click)="openDocumentDialog()">{{labels.show_all + ' (' + docCount + ')'}}</button>
                    <button mat-button color="primary" (click)="selectFile()">{{labels.add}}</button>
                </mat-card-actions>
            </mat-card>
        </div>

        <div [style]="{width: isMobile ? '100%' : '49%'}">
            <mat-card fxLayout="column" style="min-height: 160px;">
                <mat-card-header>
                    <mat-card-title>
                        <div class="card-title">{{labels.forms}}</div>
                    </mat-card-title>
                </mat-card-header>

                <mat-card-content>
                    <div class="content" *ngIf="customForms && customForms.length">
                        <mat-chip-listbox>
                            <mat-chip-option *ngFor="let form of customForms" selected="true"
                                (click)="openCustomFormModal(form)" [color]="getCustomFormColor(form)">
                                {{form.label}}
                            </mat-chip-option>
                        </mat-chip-listbox>
                    </div>
                </mat-card-content>

                <div fxFlex="stretch"></div>
            </mat-card>
        </div>
    </div>

    <div fxLayoutGap="20px" fxLayout="row" fxLayout.xs="column">
        <div [style]="{width: isMobile ? '100%' : '49%'}">

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <div class="card-title" style="margin-bottom: 0;">{{labels.network}}</div>
                </mat-expansion-panel-header>

                <div>
                    <ng-template matExpansionPanelContent>
                        <app-customer-network [customer]="customer"></app-customer-network>
                    </ng-template>
                </div>
            </mat-expansion-panel>
        </div>

        <div [style]="{width: isMobile ? '100%' : '49%'}">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <div class="card-title" style="margin-bottom: 0;">{{labels.membership}}</div>
                </mat-expansion-panel-header>

                <div class="content" *ngIf="tableCfg?.data?.length">
                    <data-table [configOptions]="tableCfg" (onSelectRowAction)="handleRowAction($event)"
                    (onSelectRow)="handleRowSelect($event)">
                    </data-table>
                </div>

                <mat-action-row align="end">
                    <button mat-button color="primary" [disabled]="isUpdatingMembership"
                        [matMenuTriggerFor]="menu">{{labels.add}}
                    </button>
                    <mat-menu #menu="matMenu">
                        @for (membership of orgMemberships; track $index) {
                        <button mat-menu-item (click)="membershipSelected(membership)">{{membership.title}}</button>
                        }
                    </mat-menu>
                </mat-action-row>
            </mat-expansion-panel>
        </div>
    </div>
</div>

<!-- hidden elements -->
<input #userImport type="file" (change)="onFileSelected($event)" [hidden]="true" />