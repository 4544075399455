import { take, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    ICustomFormField,
    ICustomFormModel,
    ICustomFormModelData
} from '@shared/model';
import { IObjectMap } from '@shared/interface';
import { cloneDeep, flatten, keys } from 'lodash';
import { OrgServiceService } from '@core/services/org-service.service';
import { WorkingAreaService } from '@core/services/workingarea.service';
import { Observable, combineLatest, from } from 'rxjs';
import { FirestoreService, DocItem } from '@core/services/firestore.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import  moment from 'moment';
import { RoleTypes } from '@models/enum';

type DocumentReference = firebase.default.firestore.DocumentReference;

@Injectable({
    providedIn: 'root',
})
export class CustomFormsService {
    private orgId: string;

    public constructor(
        private afsDB: FirestoreService,
        private orgServiceService: OrgServiceService,
        private workingAreaService: WorkingAreaService,
        private localStorageService: LocalStorageService
    ) {
        this.orgId = this.localStorageService.getItemSync('user_organization');
        this.initConsoleTool();
    }

    async initCustomFormsPromise(): Promise<IObjectMap<ICustomFormModel>> {
        const forms = await this.getOrganizationForms().pipe(take(1)).toPromise();
        const formsMap: IObjectMap<ICustomFormModel> = {};
        forms.forEach((form: ICustomFormModel) => {
            formsMap[form.id] = form;
        });

        return formsMap;
    }

    initConsoleTool() {
        window[`customForms`] = {
            getById: async (id: string) => {
                const form = await this.getCustomFormById(id).pipe(take(1)).toPromise();
                console.log(form);
                console.log(JSON.stringify(form));
            },
            saveForm: async (json: string) => {
                if (json) {
                    let form;
                    try {
                        form = JSON.parse(json);
                    } catch (e) {
                        console.error('Invalid JSON');
                        throw e;
                    }
                    this.updateCustomForm(form);
                } else {
                    console.error('Invalid params');
                }
            }
        };
    }

    createCustomForm(form: ICustomFormModel): Promise<DocumentReference> {
        form.id = this.afsDB.getNewId();
        return this.afsDB.setDoc(`organizations/${this.orgId}/customForms/${form.id}`, form as DocItem);
    }

    updateCustomForm(form: ICustomFormModel): Promise<DocumentReference> {
        return this.afsDB.update(`organizations/${this.orgId}/customForms/${form.id}`, form as DocItem);
    }

    deleteCustomForm(formId: string): Promise<DocumentReference> {
        return this.afsDB.remove(`organizations/${this.orgId}/customForms/${formId}`);
    }

    createUserCustomForm(form: ICustomFormModelData): Promise<DocumentReference> {
        return this.afsDB.add(`organizations/${this.orgId}/customUserForms`, form);
    }

    updateUserCustomForm(form: ICustomFormModelData): Promise<DocumentReference> {
        return this.afsDB.update(`organizations/${this.orgId}/customUserForms/${form.id}`, form as DocItem);
    }

    getCustomUserFormsByFormId(
        formId: string,
        date?: string,
        limit?: number
    ): Promise<ICustomFormModelData[]> {
        return new Promise(resolve => {
            this.afsDB
                .colWithIds$(`organizations/${this.orgId}/customUserForms`, ref => {
                    ref = ref.where('formId', '==', formId);

                    if (date) {
                        ref = ref.where('log.createdAt', '>=', moment(date).toDate());
                    }

                    if (limit > 0) {
                        ref = ref.limit(limit);
                    }

                    return ref;
                })
                .pipe(take(1))
                .subscribe((res: ICustomFormModelData[]) => {
                    resolve(res);
                });
        });
    }

    getCustomFormById(id: string): Observable<ICustomFormModel> {
        return this.afsDB.docWithId$(`/organizations/${this.orgId}/customForms/${id}`);
    }

    public batchDeleteUserCustomForms(ids: string[]): Promise<void> {
        return this.afsDB.batchDelete(ids, `/organizations/${this.orgId}/customUserForms`);
    }

    getUserCustomForms(userId: string): Observable<ICustomFormModelData[]> {
        const colRef = `/organizations/${this.orgId}/customUserForms`;

        return this.afsDB.colWithIds$(colRef, (ref) => {
            return ref.where('userId', '==', userId);
        });
    }

    getUserCustomFormById(userId: string, formId: string): Promise<ICustomFormModelData> {
        const colRef = `/organizations/${this.orgId}/customUserForms`;

        return new Promise((resolve) => {
            this.afsDB.colWithIds$(colRef, (ref) => {
                return ref
                    .where('formId', '==', formId)
                    .where('userId', '==', userId)
                    .limit(1);
            })
                .pipe(take(1))
                .subscribe((res: ICustomFormModelData[]) => {
                    resolve(res[0]);
                });
        });
    }

    public getCustomFormsForManagement(workingAreaId: string, serviceId: string): Observable<ICustomFormModel[]> {
        return combineLatest([
            this.orgServiceService.getServiceById(serviceId).pipe(
                map(service => service ? service.customForms : [])
            ),
            this.workingAreaService.getWorkingAreaById(workingAreaId).pipe(
                map(w => w ? keys(w.connectedCustomForms) : [])
            )
        ]).pipe(
            mergeMap(list => {
                // @TODO This is not optimal. Why do we have to get all org forms just to get a few forms?
                // E.g if we need only one form, we don't need all org forms here
                const formIds = flatten(list);
                const mapObs = from(this.initCustomFormsPromise());

                const res = mapObs.pipe(
                    map(formsMap => {
                        let result = formIds.map(id => {
                            return formsMap[id];
                        });

                        result = result.filter((a, b) => result.indexOf(a) === b);
                        return result;
                    })
                );

                return res;
            })
        );
    }

    // async getCustomFormsForRequest(request: Request): Promise<ICustomFormModel[]> {
    //     return this.getCustomFormsForManagement(request.management).pipe(take(1)).toPromise();
    // }

    public getOrganizationForms(roleType?: RoleTypes): Observable<ICustomFormModel[]> {
        const colRef = `/organizations/${this.orgId}/customForms`;

        return this.afsDB.colWithIds$(colRef, ref => {
            if (roleType == RoleTypes.excecutor) ref = ref.where('visibleForExecutor', '==', true);
            return ref;
        });
    }

    public getFormsForWorkingArea(workareaId: string): Observable<ICustomFormModel[]> {
        return new Observable(observer => {
            this.workingAreaService.getWorkingAreaById(workareaId).pipe(
                map(w => w ? keys(w.connectedCustomForms) : [])
            ).subscribe(async (formIds) => {
                const formsMap: IObjectMap<ICustomFormModel> = await this.initCustomFormsPromise();
                const result = formIds.map(id => {
                    return formsMap[id];
                });

                observer.next(result);
            });
        });
    }

    getFormsFromIds(formIds: string[]): Observable<ICustomFormModel[]> {
        return this.afsDB.docsFromId$<ICustomFormModel>(
            `/organizations/${this.orgId}/customForms`,
            formIds
        );
    }

    extendDefaultForm(defaultForm: ICustomFormModel, userForm: ICustomFormModelData): ICustomFormModel {
        const customForm: ICustomFormModel = cloneDeep(defaultForm);

        if (userForm && userForm.id) {
            customForm.id = userForm.id;
            customForm.fields.forEach((field: ICustomFormField) => {
                if (userForm.fields[field.fieldName]) {
                    field.value = userForm.fields[field.fieldName];
                }
            });
        }

        return customForm;
    }
}
