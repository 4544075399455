<app-modal>
    <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
        <span class="modal_title">{{labels.address}}</span>
    </div>

    <div class="modal_body" #modal_body style="padding: 15px;">
        <app-address [currentAddress]="address" (address)="updateAddress($event)"></app-address>
    </div>

    <div class="modal_footer" #modal_footer fxLayoutGap="10px" fxLayout="row">
        <button [disabled]="!canSave" mat-raised-button color="primary" (click)="save()">
            {{labels.save}}
        </button>
    </div>
</app-modal>