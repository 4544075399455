export class Address {
  city: string;
  number: number;
  postalcode: string;
  street: string;
  letter?: string;
  geo?: {
    latitude: number;
    longitude: number;
  };
  mailLines: string[];
  country: string;

  constructor() {
    this.city = '';
    this.number = null;
    this.postalcode = '';
    this.street = '';
    this.letter = '';
    this.geo = { latitude: null, longitude: null };
    this.mailLines = [];
    this.country = '';
  }
}


export interface CountryApi {
  name: string;
  iso2: string;
  iso3: string;
}

export interface MatchingAddresses {
  matches: {
    value: string
    label: string;
    description: string;
    precision: "Address" | "Street" | "PostalCode" | "Locality";
    context: string;
    highlights: string[][];
  }[];
  newContext: string | null;
}

export interface AddressApi {
  value: string
  label: string;
  description: string;
  precision: "Address" | "Street" | "PostalCode" | "Locality";
  context: string;
  highlights: string[][];
}