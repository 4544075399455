import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelService } from '@core/services';
import { PlannedLeave } from '@models/model';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-planned-leave',
  templateUrl: './planned-leave.component.html',
  styleUrl: './planned-leave.component.scss'
})
export class PlannedLeaveComponent implements OnInit {

  public labels = this.labelService.defaultProvider();
  protected form: FormGroup;
  protected plannedLeave: PlannedLeave;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { plannedLeave: PlannedLeave },
    public breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<PlannedLeaveComponent>,
    private labelService: LabelService,
    private fb: FormBuilder
  ) { }

  async ngOnInit(): Promise<void> {
    this.plannedLeave = cloneDeep(this.data.plannedLeave);
    this.labels = (await this.labelService.getLabels('app-planned-leave')).data;
    this.setupForm();
  }

  private setupForm() {
    this.form = this.fb.group<PlannedLeave>({
      from: [this.plannedLeave.from, Validators.required] as any,
      to: [this.plannedLeave.to, Validators.required] as any,
      remark: [this.plannedLeave.remark, Validators.required] as any
    })
  }

  protected save() {
    this.dialogRef.close(this.form.value);
  }
}
